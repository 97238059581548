<template>
  <a-row :gutter="16">
    <a-col :span="24">
      <a-form-item label="Nama Kegiatan">
        <a-input :disabled="isDisabled" v-model="dataKegiatan.nama" size="large" />
      </a-form-item>
    </a-col>
    <a-col :span="24">
      <a-form-item label="Jenis Kegiatan">
        <a-select :disabled="isDisabled" size="large" class="w-100" :value="dataKegiatan.jenis" @change="(e) => handleChange(e, 'jenis')">
          <a-select-option v-for="(item, index) in kegiatan" :key="index" :value="item.nama.toLowerCase()">
            {{item.nama}}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <template v-if="dataKegiatan.jenis === 'libur'">
      <a-col :span="24">
        <a-form-item label="Tanggal Kegiatan">
          <a-date-picker :disabled="isDisabled" size="large" class="w-100" placeholder="" :value="dataKegiatan.tanggal_awal" :disabled-date="disabledBeforeNow" @change="(e) => handleChange(e, 'tanggal_awal')" />
        </a-form-item>
      </a-col>
      <template v-if="!isDisabled">
        <a-col :span="24">
          <a-form-item label="Pengulangan Tanggal">
            <a-radio-group :disabled="isDisabled" size="large" class="w-100" default-value="active" :value="dataKegiatan.status_pengulangan" @change="(e) => handleChange(e.target.value, 'status_pengulangan')" button-style="solid">
              <a-radio-button class="w-50 text-center" value="active">
                Aktif
              </a-radio-button>
              <a-radio-button class="w-50 text-center" value="inactive">
                Tidak Aktif
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <template v-if="dataKegiatan.status_pengulangan === 'active'">
          <a-col :span="24">
          <a-form-item label="Pengulangan Sampai (tahun)">
            <a-select :disabled="isDisabled" size="large" class="w-100" :value="dataKegiatan.pengulangan_sampai" @change="(e) => handleChange(e, 'pengulangan_sampai')">
              <a-select-option v-for="(item, index) in afterThisYearList" :key="index" :value="item.nama">
                {{item.nama}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        </template>
      </template>
    </template>
    <template v-if="dataKegiatan.jenis === 'acara'">
      <a-col :span="12">
      <a-form-item label="Tanggal Awal Periode">
        <a-date-picker
          :disabled="isDisabled"
          :value="dataKegiatan.tanggal_awal"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD"
          @openChange="handleStartOpenChange"
          @change="(e) => handleChange(e, 'tanggal_awal')"
          class="w-100"
          size="large"
          placeholder=""
        />
      </a-form-item>
    </a-col>
    <a-col :span="12">
      <a-form-item label="Tanggal Akhir Periode">
        <a-date-picker
          :disabled="isDisabled"
          :value="dataKegiatan.tanggal_akhir"
          :disabled-date="disabledEndDate"
          format="YYYY-MM-DD"
          :open="endOpen"
          @openChange="handleEndOpenChange"
          @change="(e) => handleChange(e, 'tanggal_akhir')"
          class="w-100"
          size="large"
          placeholder=""
        />
      </a-form-item>
    </a-col>
    </template>
    <a-col :span="24">
      <a-form-item label="Deskripsi Kegiatan">
        <a-textarea
          :disabled="isDisabled"
          v-model="dataKegiatan.deskripsi"
          :auto-size="{ minRows: 5, maxRows: 7 }"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment'
import { bulan, tahun } from '@/helpers/pertanggalanIndonesia'
const kegiatan = [
  {
    nama: 'Libur',
  },
  {
    nama: 'Acara',
  },
]
export default {
  name: 'FormKegiatan',
  props: {
    dataKegiatan: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      kegiatan,
      bulan,
      tahun,
      endOpen: false,
    }
  },
  methods: {
    disabledBeforeNow(value) {
      return value.valueOf() < moment().add(-1, 'day').valueOf()
    },
    disabledStartDate(startValue) {
      const endValue = this.dataKegiatan.tanggal_akhir
      if (!startValue || !endValue) {
        return false
        // return this.disabledBeforeNow(startValue)
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataKegiatan.tanggal_awal
      if (!endValue || !startValue) {
        return false
        // return this.disabledBeforeNow(endValue)
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      console.log(value, column)
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  computed: {
    afterThisYearList() {
      const thisYear = moment().format('YYYY')
      const findNextYear = this.tahun.filter(year => year.nama > thisYear)
      return findNextYear
    },
  },
}
</script>

<style>

</style>
